<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form ref="form">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase text-left">Registration Information</h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left" htmlFor="name"> Name <sup style="color: red">*</sup></label>
              <input name="name" type="text" :class="errors.name ? errorClass : normalClass" v-model="data.name" v-on:keyup="validate('name')" required />
              <p v-if="errors.name" class="text-red-500 text-xs italic text-left">Full name is required. E.g. Firstname Surname.</p>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left" htmlFor="email"> Email <sup style="color: red">*</sup></label>
              <input name="email" type="text" :class="errors.email ? errorClass : normalClass" v-model="data.email" v-on:keyup="validate('email')" required />
              <p v-if="errors.email" class="text-red-500 text-xs italic text-left">Email is required.</p>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left" htmlFor="phone"> Phone Number <sup style="color: red">*</sup></label>
              <input name="phone" type="phone" :class="errors.phone ? errorClass : normalClass" v-model="data.phone" v-on:keyup="validate('phone')" />
              <p v-if="errors.phone" class="text-red-500 text-xs italic text-left">Invalid phone number</p>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left" htmlFor="gender"> Gender </label>
              <v-select name="gender" :options="genders" v-model="data.gender" :class="errors.gender ? errorClass : normalClass"></v-select>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase text-left">Company Information</h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left" htmlFor="company"> Company </label>
              <input type="text" name="company" :class="errors.company ? errorClass : normalClass" v-model="data.company" v-on:keyup="validate('company')" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left" htmlFor="position"> Position </label>
              <input type="text" name="position" :class="errors.position ? errorClass : normalClass" v-model="data.position" v-on:keyup="validate('position')" />
            </div>
          </div>
        </div>

	<!--
        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase text-left">About You</h6>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left" htmlFor="dob"> Date of Birth</label>
              <Datepicker v-model="data.dob" :class="errors.dob ? errorClass : normalClass" :format="format" :maxDate="new Date()" :enableTimePicker="false" preventMinMaxNavigation />
              <p v-if="errors.dob" class="text-red-500 text-xs italic text-left">Invalid date of birth</p>
            </div>
          </div>
        </div>
	-->
      </form>
    </div>
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex" :class="source !== 'qrcode' ? ['justify-between'] : ['justify-center']">
        <button
          class="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          v-show="source !== 'qrcode'"
          @click="closeForm"
        >
          Use QR Code
        </button>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="submit"
          @click="validateAll"
        >
          Register
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import is from 'is_js'
import { useShowSuccess, useShowError } from '@/composables'
import programService from '../../api/program'

export default {
  props: ['programId', 'source'],
  emits: ['closeForm', 'submitForm'],
  computed: {
    canSubmit: function () {
      return is.not.empty(this.data.name) && is.not.empty(this.data.email)
    }
  },
  data() {
    return {
      normalClass: 'border-0 px-3 py-3 mb-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150',
      errorClass: 'px-3 py-3 mb-3  border-2 border-red-500 rounded text-sm w-full',
      genders: ['Female', 'Male', 'Other'],
      textInputOptions: {
        format: 'yyyy-MM-dd'
      },
      data: {
        name: '',
        email: '',
        phone: '',
        position: '',
        company: '',
        dob: '',
        gender: ''
      },
      // data: { name: 'Kent Clark', email: 'kk@gfd.coms', phone: '0549959580', position: '', company: 'Yemaachi Biotech Limited', dob: '2022-07-7', gender: 'Other', program_id: 1, source: 'web' },
      errors: {
        name: false,
        email: false,
        phone: false,
        position: false,
        company: false,
        dob: false,
        gender: false
      }
    }
  },
  methods: {
    closeForm() {
      this.$emit('closeForm', true)
    },
    onSubmit(data) {
      let self = this

      data.program_id = this.programId
      data.source = this.source

      programService
        .addAttendee(data)
        .then(() => {
          self.data = {
            name: '',
            email: '',
            phone: '',
            position: '',
            company: '',
            dob: '',
            gender: ''
          }
          self.closeForm()
          useShowSuccess('You have been successfully registered. An email will sent as confirmation.')
        })
        .catch((errors) => {
          const err = errors.error.response.data

          if (is.not.undefined(err) && err.name === 'ValidationError') {
            console.log(err)
            err.details.forEach((e) => {
              Object.keys(e).forEach((i) => {
                self.errors[i] = true
              })
            })
          } else {
            console.log(errors)
          }
        })
    },
    validateAll() {
      let self = this
      let hasError = false
      this.validateName(this.data.name)
      this.validateEmail(this.data.email)
      this.validateMobile(this.data.phone)
      // this.validateDob(this.data.dob)
      Object.keys(this.errors).forEach((k) => {
        if (self.errors[k] === true) {
          hasError = true
        }
      })
      if (!hasError) {
        // this.data.dob = this.format(this.data.dob)
        this.onSubmit(this.data)
      } else {
        useShowError('Please provide all the required information.')
      }
    },
    validate(attr) {
      if (attr == 'name') this.validateName(this.data.name)
      if (attr == 'email') this.validateEmail(this.data.email)
      if (attr == 'phone') this.validateMobile(this.data.phone)
      if (attr == 'dob') this.validateDob(this.data.dob)
    },
    validateName(value) {
      if (is.not.undefined(value) && is.not.empty(value) && value.indexOf(' ') !== -1) {
        this.errors.name = false
      } else {
        this.errors.name = true
      }
    },
    validateEmail(value) {
      if (value === '' || is.not.email(value)) {
        this.errors.email = true
      } else {
        this.errors.email = false
      }
    },
    validateMobile(value) {
      let reg = /^\d+$/
      if (is.empty(value)) {
        this.errors.phone = true
        return
      }
      if (is.startWith(value, '0') && value.length == 10 && reg.test(value)) {
        this.errors.phone = false
      } else {
        this.errors.phone = true
      }
    },
    validateDob(value) {
      if (is.empty(value)) {
        this.errors.dob = false
        return
      }
      let e = new Date(value)
      if (is.nan(e.getTime())) {
        this.errors.dob = true
        return
      }
      let s = new Date()
      if (is.not.nan(s.getTime())) {
        if (s < e) {
          this.errors.dob = true
          return
        }
      }
      this.errors.dob = false
    },
    format(date) {
      let e = new Date(date)
      if (is.nan(e.getTime())) {
        return ''
      }
      const day = e.getDate()
      const month = e.getMonth() + 1
      const year = e.getFullYear()

      return `${year}-${month}-${day}`
    }
  }
}
</script>
<style>
.vs__dropdown-toggle,
.dp__input,
.vs__search,
.vs--searchable {
  border: transparent !important;
}
</style>
